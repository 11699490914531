@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
$backgroundColor: #f3f2ef;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app {
  //background-image: url("./background2.jpg");
  //background-position: center; /* Center the image */
  //background-repeat: repeat; /* Do not repeat the image */
  //background-size: cover; /* Resize the background image to cover the entire container */
  background-color: $backgroundColor;
  height: 100vh;
  font-family: 'Poppins', sans-serif;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

}