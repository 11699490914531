$card-padding: 24px 32px;
$card-padding-mobile: 24px 32px;
$border-radius: 8px;
$backgroundColor: rgba(255, 255, 255, 0.98);
$backgroundBlur: 8px;
$clr-header: #171717;
$black-100: #2d2d2d;
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 32px;
  width: 95%;
  height: calc(100% - 15vh - 24px);
  margin: 24px auto;
}

.addressSection {
  flex: 3;
  height: 100%;
  width: 100%;
  padding: $card-padding;
  overflow: hidden;

  .mapWrapper {
    margin-top: 24px;
    width: 100%;
    height: 100%;

    .map {
      width: 100%;
      height: 100%;
      border: 0;
    }
  }


  .address {
    h1.header {
      color: $black-100;
      line-height: 1.5;
      letter-spacing: 0.8px;
      font-weight: 600;
      font-size: 32px;
      margin: 4px auto;
    }

    .content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 24px;
      margin-top: 24px;
      color: $black-100;
      font-size: 18px;

      svg {
        height: 30px;
        width: 30px;
      }
    }
  }
}

.contact {
  flex: 2;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  column-gap: 24px;
  row-gap: 0;
  padding: $card-padding;
  background-color: $backgroundColor;
  backdrop-filter: blur($backgroundBlur);
  border-radius: $border-radius;

  h1.context {
    color: $clr-header;
    line-height: 1.5;
    letter-spacing: 0.8px;
    font-weight: 600;
    font-size: 32px;
    margin: 4px auto;
  }

  .mailAddress, .phone {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 16px;
    color: $clr-header;
    margin-bottom: 12px;

    svg {
      width: 24px;
      height: 24px;
    }

    a {
      color: $clr-header;
      letter-spacing: 0.8px;
      font-size: 24px;
      text-decoration: none;
    }
  }

  .orWrapper {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    column-gap: 16px;
    width: 50%;
    color: $clr-header;
    line-height: 1.5;
    letter-spacing: 0.8px;
    font-weight: 600;
    font-size: 24px;

    span {
      text-align: center;
      width: 50%;
    }

    hr {
      flex: 1;
      width: 100%;
      height: 2px;
    }
  }

  .socialMedias {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 32px;
    width: 100%;

    .media {
      svg {
        height: 80px;
        width: 80px;
        fill: $black-100;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    margin-top: 24px;
    margin-bottom: 24px;
    flex-direction: column;
    height: auto;
    .addressSection {
      flex: 1;
      margin-bottom: 32px;
      overflow: unset;
      height: 85vh;

      .address {
        .content {
          svg {
            height: 60px;
            width: 60px;
          }
        }
      }

      .mapWrapper {
        height: 400px;
      }
    }

    .contact {
      flex: 1;
      padding: $card-padding-mobile;

      .context {
        font-size: 24px;
        margin-top: 18px;
      }

      .mailAddress {
        font-size: 18px;
        word-break: break-all;
        margin-top: 12px;
      }
      .mailAddress, .phone {
        a{
          font-size: 18px;
        }
      }
      .phone {
        margin-top: 12px;
      }

      .orWrapper {
        width: 100%;
        font-size: 18px;
        margin-top: 12px;

        span {
          text-align: center;
          width: 50%;
        }

        hr {
          flex: 1;
          width: 100%;
          height: 4px;
        }
      }

      .socialMedias {
        margin-top: 12px;

        .media {
          margin-top: 12px;

          svg {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
}