$backgroundColor: #ee0000;
$mobileBackgroundColor:  #ee0000;
$backgroundBlur: 16px;
$border-radius: 0px;
$font-color: #f6f6f6;


.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 12;

}

.wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  min-height: 10vh;
  margin: 0 auto;
  background-color: $backgroundColor;
  border-radius: $border-radius;
  backdrop-filter: blur($backgroundBlur);
}

.logo {
  display: flex;
  justify-content: space-around;
  align-items: center;
  column-gap: 12px;

  .icon {
    width: 40px;
    height: 40px;
  }

  list-style: none;
  text-decoration: none;
  color: $font-color;
  letter-spacing: 5px;
  font-size: 24px;
  font-weight: 600;

  &:hover {
    color: black;
    cursor: pointer;
  }
}

.links {
  display: flex;
  width: 30%;
  height: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  li {
    list-style: none;
  }

  a {
    margin: 0 24px;
    text-decoration: none;
    letter-spacing: 3px;
    color: $font-color;
    font-size: 16px;
    font-weight: 600;

    &:hover {
      color: black;
      cursor: pointer;
    }
  }
}

.burger {
  display: none;

  div {
    width: 25px;
    height: 3px;
    background-color: $font-color;
    margin: 5px;
    transition: all 0.3s ease;
  }

  &.active {
    .line1 {
      transform: rotate(-405deg) translate(-5px, 6px);
    }

    .line2 {
      opacity: 0;
    }

    .line3 {
      transform: rotate(405deg) translate(-5px, -6px);
    }
  }
}

@media screen and (max-width: 1024px) {
  .links {
    width: 60%;
  }
}

@keyframes navLinkOpen {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes navLinkClose {
  from {
    opacity: 1;
    transform: translateX(0px);
  }
  to {
    opacity: 0;
    transform: translateX(50px);
  }
}

@mixin transitionIn {
  @for $i from 1 through 3 {
    &:nth-child(#{$i}) {
      animation: navLinkOpen 0.5s ease forwards #{$i / 7 + 0.5}s;
    }
  }
}

@mixin transitionOut {
  @for $i from 1 through 3 {
    &:nth-child(#{$i}) {
      animation: navLinkClose 0.5s ease backwards #{$i * 0.1 + 0.05}s;
    }
  }
}

@media screen and (max-width: 768px) {
  body {
    height: 100%;
    overflow-x: hidden;
  }
  .logo{
    font-size: 24px;
  }
  .wrapper {
    margin: 0;
    width: 100%;
    z-index: 11;
    border-radius: 0;
    transition: transform 0.8s ease-in, background-color 0.8s ease-in;
    min-height: 15vh;

    &.active {
      background-color: $mobileBackgroundColor;
    }

    &:not(&.active) {
      transition: transform 0.8s ease-out, background-color 0.8s ease-out;

      li {
        @include transitionOut;
      }
    }
  }
  .links {
    position: absolute;
    right: 0;
    height: 85vh;
    top: 15vh;
    background-color: $backgroundColor;

    flex-direction: column;
    align-items: center;
    width: 100%;
    transform: translateX(100%);
    transition: background-color 0.5s ease-in,
    transform 0.5s ease-in;
    display: none;
    li {
      opacity: 0;
      @include transitionIn;

      a {
        font-size: 24px;
      }
    }

    &.active {
      transform: translateX(0%);
      background-color: $mobileBackgroundColor;
      display: flex;
    }

    &:not(&.active) {
      transition: transform 0.8s ease-out, background-color 0.5s ease-out;

      li {
        @include transitionOut;
      }
    }
  }
  .burger {
    display: block;
    cursor: pointer;
  }
}

