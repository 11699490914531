$backgroundColor: rgba(74, 103, 103, 0.63);

.wrapper {
  position: relative;

  .phones {
    position: absolute;
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {

    .phones {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      row-gap: 32px;
      right: 0;
      top: 40px;
      z-index: 10;

      .phone {
        transform: translateX(25px);
        a {
          text-decoration: none;
          color: white;

        }

        background-color: $backgroundColor;
        border-radius: 24px;
        height: 60px;
        width: 80px;

        .iconWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 10px;
          position: relative;

          .number {
            position: absolute;
            font-size: 16px;
            font-weight: bold;
            top: 4px;
            transform: translateX(-12px);
          }

          svg {
            height: 60px;
            width: 60px;
          }
        }

      }
    }
  }
}