$backgroundColor: rgba(255, 255, 255, 0.98);
$backgroundBlur: 8px;
$clr-header: #171717;
$card-padding: 24px 32px;
$card-padding-mobile: 24px 32px;
$border-radius: 8px;
$black-100: #2d2d2d;

.sectionHeader {
  width: 90%;
  margin: 32px auto 16px;
}

.aboutUsWrapper {
  width: 90%;
  margin: 32px auto 16px;
  background-color: $backgroundColor;
  backdrop-filter: blur($backgroundBlur);
  border-radius: $border-radius;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.phoneSection {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 24px;
  row-gap: 0;
  padding: $card-padding;


  h1 {
    padding: 0 12px;
    margin-top: 16px;
    width: 100%;
    color: $clr-header;
    font-size: 48px;
    letter-spacing: 0.3px;
  }

  .phone {
    color: $black-100;
    margin-bottom: 12px;
    margin-top: 12px;

    svg {
      width: 24px;
      height: 24px;
    }

    a {
      color: $black-100;
      letter-spacing: 0.8px;
      font-size: 24px;
      text-decoration: none;
      text-align: left;
    }
  }
}

.aboutUs {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  column-gap: 24px;
  row-gap: 0;
  padding: $card-padding;


  h1 {
    padding: 0 24px;
    margin-top: 16px;
    width: 100%;
    color: #ee0000;
    font-size: 48px;
    letter-spacing: 0.3px;
  }

  span {
    padding: 12px 24px;
    margin-top: 8px;
    height: min-content;
    font-size: 18px;
    letter-spacing: 0.3px;
    color: $black-100;

  }
}

.wrapper {
  width: 95%;
  margin: 0 auto 24px;
  padding: $card-padding;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 32px;
  column-gap: 64px;

  .card {
    flex: 1;
    height: 540px;
    background-color: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 20px 20px 28px -8px rgba(0, 0, 0, 0.61);
    border-radius: 4px;

    h1 {
      padding: 0 24px;
      margin-top: 16px;
      width: 100%;
      font-size: 24px;
    }

    span {
      padding: 0 24px;
      margin-top: 8px;
      max-height: 160px;
      text-overflow: ellipsis;
      color: $black-100;
    }

    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .aboutUsWrapper {
    flex-direction: column;

    h1 {
      font-size: 32px;
    }
  }
  .wrapper {
    grid-template-columns: 1fr;

    .card {
      height: 560px;

      span {
        max-height: 170px;
      }
    }
  }
  .phoneSection {
    width: 100%;
    padding: 12px 18px 32px;
  }
  .aboutUs {
    width: 100%;
    padding: 12px 18px 12px;

    h1 {
      padding: 0 12px;
    }

    span {
      padding: 0 12px;
    }
  }
}
