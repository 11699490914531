.slideContainer {
  width: 95%;
  height: 90vh;
  margin: 0 auto;
  padding: 12px;
}

.wrapper {
  height: calc(90vh - 54px);
}

.eachFade {
  display: flex;
  width: 100%;
  height: 100%;
}

.eachFade > div {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.eachFade > div img {
  width: 98%;
  height: 100%;
  object-fit: scale-down;
}

@media screen and (max-width: 768px) {
  .wrapper {
    height: calc(85vh - 54px);
  }
}
